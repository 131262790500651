import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useAtom } from 'jotai'
import { Box, Center, Divider, Heading, StackDivider, VStack, List, ListItem, Link, Text } from '@chakra-ui/react'

import { breadcrumbAtom } from '@!/atoms/breadcrumbAtom'
import { SearchForm } from '@!/Store/Common/containers'
import { PointBackBanner, CampaignHeaderBanner } from '@!/components/Banner'
import { buildUrlString, isBlank } from '@!/Util'
import { Paginate } from '@!/components/Paginate'

const Authors = ({ authors, q, currentPage, totalCount, perPage, campaign }) => {
  const [, setBreadcrumb] = useAtom(breadcrumbAtom)
  const titleName = `「${q}」から始まる作家`

  useEffect(() => {
    setBreadcrumb([{ name: 'マンガBANGブックス', ref: '/' }, { name: titleName }])
  }, [])

  const handlePageClick = (page) => {
    window.location.href = buildUrlString('/store/search/author_kana', { q: q, page: page })
  }

  return (
    <Box>
      {campaign ? <CampaignHeaderBanner campaign={campaign} /> : <PointBackBanner />}
      <SearchForm />
      <Box px="16px" py="12px">
        <Heading as="h1" fontSize="2xl" fontWeight="600" lineHeight="36px">
          {titleName}
        </Heading>
        <Box mt="12px" mb="28px">
          {!isBlank(authors) ? (
            <>
              <Divider />
              <VStack spacing={0} alignItems="start" divider={<StackDivider />}>
                <List>
                  {authors.map((author, index) => (
                    <ListItem key={`store-search-author-kana-item-${index}`} mt={2} mb={2} listStyleType="none">
                      <Link textDecoration="none" color="label.primary" fontWeight="normal" href={buildUrlString(`/store/search/author/${author}`)}>
                        {author}
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </VStack>
              <Divider />
              <Center mt="24px">
                <Paginate currentPage={currentPage} perPage={perPage} totalCount={totalCount} onPageChange={handlePageClick} />
              </Center>
            </>
          ) : (
            <Text>該当する作家が見つかりませんでした。</Text>
          )}
        </Box>
      </Box>
    </Box>
  )
}

Authors.propTypes = {
  q: PropTypes.string.isRequired,
  authors: PropTypes.array,
  currentPage: PropTypes.number,
  totalCount: PropTypes.number,
  perPage: PropTypes.number,
}

export default Authors
