import { AppProvider } from '@!/providers'
import { HomePage } from '@!/Store/Home/containers/HomePage'
import StoreApp from '@!/Store/Common/containers/StoreApp'
import { useAppSettings } from '@!/Hooks/useAppSettings'

const HomeApp = (props) => {
  const jotaiStore = useAppSettings({ isSaveLastUrl: true, alertMessage: props.alert_message })

  return (
    <AppProvider jotaiStore={jotaiStore}>
      <StoreApp {...props}>
        <HomePage home={props.home} tabName={props.tab_name} />
      </StoreApp>
    </AppProvider>
  )
}

export default HomeApp
