import { AppProvider } from '@!/providers'
import Authors from '@!/Store/Kana/Authors/containers/Authors'
import StoreApp from '@!/Store/Common/containers/StoreApp'

const AuthorsApp = (props) => {
  return (
    <AppProvider>
      <StoreApp {...props}>
        <Authors
          q={props.q}
          authors={props.authors}
          currentPage={props.current_page}
          totalCount={props.total_count}
          perPage={props.per_page}
          campaign={props.campaign_info}
        />
      </StoreApp>
    </AppProvider>
  )
}

export default AuthorsApp
