import { useState, useEffect } from 'react'
import { useAtom } from 'jotai'
import { userHashAtom } from '@!/atoms/userInfoAtom'
import { buildAssetUrlString } from '@!/Util'
import { Banner } from '@!/components/Banner'
import homeBannerNoImagePng from '@images/home_banner-noimage.png'

export const CampaignHomeBanner = ({ campaign, tabName }) => {
  const [userHash] = useAtom(userHashAtom)
  const [bannerUrl, setBannerUrl] = useState('')
  const [bannerImageUrl, setBannerImageUrl] = useState('')
  const [gtmClick, setGtmClick] = useState('')

  useEffect(() => {
    if (userHash) {
      setBannerUrl(campaign.top_banner_login_url)
      setBannerImageUrl(campaign.top_banner_login_image_url)
      setGtmClick(`${tabName}バナー_${campaign.name}_event_after`)
    } else {
      setBannerUrl(campaign.top_banner_url)
      setBannerImageUrl(campaign.top_banner_image_url)
      setGtmClick(`${tabName}バナー_${campaign.name}_event_before`)
    }
  }, [userHash, campaign, tabName])

  return <Banner src={bannerImageUrl} fallbackSrc={buildAssetUrlString(homeBannerNoImagePng)} href={bannerUrl} alt={campaign.name} gtmClick={gtmClick} />
}
