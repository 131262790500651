import { useMemo, useEffect } from 'react'
import { Box, Center, Image, VStack, Text, Flex, Divider, Grid, Heading, HStack, Stack, Link } from '@chakra-ui/react'
import { RoundButton } from '@!/components/Button'
import { useAtom } from 'jotai'
import { breadcrumbAtom } from '@!/atoms/breadcrumbAtom'
import { buildAssetUrlString } from '@!/Util'
import { getDevice } from '@!/Util/device'

import { SearchForm } from '@!/Store/Common/containers'
import { IconContext } from 'react-icons'
import { BsCheckSquareFill } from 'react-icons/bs'
import guide01TitleSvg from '@images/guide_01_title.svg'
import guide02TitleSvg from '@images/guide_02_title.svg'
import guide03TitleSvg from '@images/guide_03_title.svg'
import pointBackTextSvg from '@images/point_back_text.svg'
import prBenefitIcons from '@images/pr_benefit_icons.svg'
import pointUsageGuideTextSvg from '@images/point_usage_guide_text.svg'
import medalTicketScreenCapture from '@images/medal_ticket_capture.webp'
import storeScreenCapture from '@images/store_capture.webp'
import guideBannerPcImage from '@images/guide_banner_pc.webp'
import guideBannerImage from '@images/guide_banner.webp'

export const Guide = (props) => {
  const device = useMemo(() => getDevice(), [])
  const [, setBreadcrumb] = useAtom(breadcrumbAtom)
  const { web_features_free: webFeaturesFree, web_features_campaign: webFeaturesCampaign } = props.params.guide
  const bannerImage = device === 'pc' ? guideBannerPcImage : guideBannerImage

  useEffect(() => {
    setBreadcrumb([{ name: 'マンガBANGブックス', ref: '/' }, { name: '初めての方へ' }])
  }, [])

  const handleClick = (id) => (e) => {
    e.preventDefault()
    document.querySelector(id).scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <>
      <Heading as="h1">
        <Image src={buildAssetUrlString(bannerImage)} alt="ようこそ、マンガBANGブックス。はじめての方へ" w="100%" />
      </Heading>

      <Box bg="bg.guide">
        <Text color="label.white" fontSize="xs" textAlign="center" py={8}>
          マンガBANGブックスは人気マンガが
          <br />
          いつでもお得に読むことができる電子書籍サイトです。
          <br />
          国内最大級のマンガアプリ「マンガBANG！」の
          <br />
          姉妹サービスだから使って安心！
        </Text>

        <HStack justifyContent="center" spacing={0} divider={<Divider orientation="vertical" borderColor="divider.white" h="55px" />}>
          <Flex w={device === 'pc' ? '155px' : '114px'} h="55px" bgColor="bg.guideSubAccent" alignItems="center" justifyContent="center" position="relative">
            <Link onClick={handleClick('#point1')} textDecorationColor="label.white" textDecorationThickness="1px">
              <Text fontSize="sm" fontWeight="bold" color="label.white" textAlign="center">
                高ポイント還元
              </Text>
            </Link>
            <Box
              position="absolute"
              bottom="-10px"
              left="50%"
              transform="translateX(-50%)"
              width="0"
              height="0"
              borderLeft="8px solid transparent"
              borderRight="8px solid transparent"
              borderTop="10px solid #D2352A"
            />
          </Flex>

          <Flex w={device === 'pc' ? '155px' : '114px'} h="55px" bgColor="bg.guideSubAccent" alignItems="center" justifyContent="center" position="relative">
            <Link onClick={handleClick('#point2')} textDecorationColor="label.white" textDecorationThickness="1px">
              <Text fontSize="sm" fontWeight="bold" color="label.white" textAlign="center">
                お得な
                <br />
                キャンペーン
              </Text>
            </Link>
            <Box
              position="absolute"
              bottom="-10px"
              left="50%"
              transform="translateX(-50%)"
              width="0"
              height="0"
              borderLeft="8px solid transparent"
              borderRight="8px solid transparent"
              borderTop="10px solid #D2352A"
            />
          </Flex>

          <Flex w={device === 'pc' ? '155px' : '114px'} h="55px" bgColor="bg.guideSubAccent" alignItems="center" justifyContent="center" position="relative">
            <Link onClick={handleClick('#point3')} textDecorationColor="label.white" textDecorationThickness="1px">
              <Text fontSize="sm" fontWeight="bold" color="label.white" textAlign="center">
                かんたん利用
              </Text>
            </Link>
            <Box
              position="absolute"
              bottom="-10px"
              left="50%"
              transform="translateX(-50%)"
              width="0"
              height="0"
              borderLeft="8px solid transparent"
              borderRight="8px solid transparent"
              borderTop="10px solid #D2352A"
            />
          </Flex>
        </HStack>

        <Box mx={4} py={6}>
          <Box id="point1" textAlign="center">
            <Box bg="bg.primary" borderRadius="lg" borderWidth={1} px={3} pt={4} pb={6}>
              <Heading as="h2">
                <Image src={buildAssetUrlString(guide01TitleSvg)} alt="ポイント還元がお得！" m="0 auto" />
              </Heading>

              <Center my={10} mx={3}>
                <Image src={buildAssetUrlString(pointBackTextSvg)} alt="マンガBANGブックスならいつでも高ポイント還元" />
              </Center>

              <Text fontSize="2xs">
                ※税抜き金額からポイント還元いたします
                <br />
                ※ポイント利用分は還元対象外です
                <br />
                ※還元率はキャンペーンページ、購入確認ページでご確認いただけます。
              </Text>

              <Center>
                <Image src={buildAssetUrlString(prBenefitIcons)} alt="全作品対象、何回でも使える、還元上限なし" my={4} />
              </Center>

              <Text fontSize="2xs" wordBreak="keep-all">
                1pt = 1円としてお使いいただけます。
                <br />
                値引作品にも適用＆利用上限がないので
                <wbr />
                いつでもお得にマンガが読める！
              </Text>

              <Center position="relative" top={8}>
                <Image
                  src={buildAssetUrlString(pointUsageGuideTextSvg)}
                  alt="WEBで貯まったポイントはマンガBANG!（アプリ）内でも1pt=1コインとしてご利用いただけます。"
                  w="90%"
                  maxW="538px"
                  bgColor="bg.primary"
                  px={2}
                />
              </Center>

              <Box border="1px" borderColor="divider.dark" borderRadius="lg" py={6} px={4}>
                <Box bgColor="gray.300" borderRadius="lg" my={6}>
                  <Stack direction={{ base: 'column', md: 'row' }} divider={<Divider orientation="vertical" borderColor="divider.dark" h="auto" />} spacing={0}>
                    <VStack spacing={0} py={4} px={2} pb={0} width={{ md: '50%' }} justifyContent="space-between">
                      <Text fontSize="lg" fontWeight="black" mb={2}>
                        メダル・チケット作品
                      </Text>
                      <Image src={buildAssetUrlString(medalTicketScreenCapture)} alt="メダル・チケット作品" w="100%" maxW="287px" />
                    </VStack>
                    <VStack spacing={0} py={4} px={2} pb={0} width={{ md: '50%' }} justifyContent="space-between">
                      <Text fontSize="lg" fontWeight="black" mb={2}>
                        マンガBANGストア
                      </Text>
                      <Image src={buildAssetUrlString(storeScreenCapture)} alt="マンガBANGストア" maxW="135px" />
                    </VStack>
                  </Stack>
                </Box>
                <Text fontSize="2xs">※アプリでポイントをお使いいただくためには、事前にアプリで会員登録の上、マンガBANGブックスでログインが必要です。</Text>
              </Box>

              <Box maxW="319px" m="0 auto" pt={2}>
                <SearchForm />
                <RoundButton bgColor="bg.guideAccent" color="black" as="a" href="/user/login#/registrations" mt={4} fontWeight="bold">
                  かんたん60秒! 無料会員登録はこちら
                </RoundButton>
              </Box>
            </Box>
          </Box>

          <Box id="point2" pt={8} textAlign="center">
            <Box bg="bg.primary" borderRadius="lg" borderWidth={1} px={3} pt={4} pb={6}>
              <Heading as="h2" mb={6}>
                <Image src={buildAssetUrlString(guide02TitleSvg)} alt="お得なキャンペーンが充実！" m="0 auto" />
              </Heading>

              <Box bgColor="gray.300" borderRadius="lg" my={6} py={4} px={3}>
                <Heading as="h3" fontSize="xl" pb={4}>
                  無料マンガ＆値引作品は
                  <br />
                  毎週追加
                </Heading>

                <Box bg="bg.primary" borderRadius="lg" py={4}>
                  <Text fontSize="2xs">
                    42万作品以上を配信！
                    <br />
                    豊富な品揃えだけじゃない
                    <br />
                    無料マンガやキャンペーンも充実！
                  </Text>
                  <Center fontSize="xl" fontWeight="black" my={4} position="relative" whiteSpace="nowrap">
                    <Box as="span" width="31.3px" height="1px" backgroundColor="black" transform="rotate(63.43deg)" mr={-2} />
                    さっそく作品を探しにいく
                    <Box as="span" width="31.3px" height="1px" backgroundColor="black" transform="rotate(-63.43deg)" ml={-2} />
                  </Center>

                  <Stack direction={{ base: 'column', md: 'row' }} spacing={0}>
                    <VStack spacing={0} px={4} width={{ md: '50%' }} justifyContent="space-between">
                      <RoundButton as="a" href={`/store/features/${webFeaturesFree}`} mt={2} fontWeight="bold">
                        おすすめ無料特集
                      </RoundButton>
                    </VStack>
                    <VStack spacing={0} px={4} width={{ md: '50%' }} justifyContent="space-between">
                      <RoundButton as="a" href={`/store/features/${webFeaturesCampaign}`} mt={2} fontWeight="bold">
                        キャンペーンをみる
                      </RoundButton>
                    </VStack>
                  </Stack>
                </Box>
              </Box>

              <Box maxW="319px" m="0 auto">
                <RoundButton bgColor="bg.guideAccent" color="black" as="a" href="/user/login#/registrations" fontWeight="bold">
                  かんたん60秒! 無料会員登録はこちら
                </RoundButton>
              </Box>
            </Box>
          </Box>

          <Box id="point3" pt={8} textAlign="center">
            <Box bg="bg.primary" borderRadius="lg" borderWidth={1} px={3} pt={4} pb={6}>
              <Heading as="h2" mb={6}>
                <Image src={buildAssetUrlString(guide03TitleSvg)} alt="簡単無料登録＆連携" m="0 auto" />
              </Heading>

              <Text fontSize="2xs">
                国内最大級のマンガアプリ「マンガBANG!」の
                <br />
                姉妹サービスだから使って安心！
              </Text>

              <Center bgColor="gray.300" borderRadius="lg" mt={6} mb={2} py={6} px={4}>
                <Box w="fit-content">
                  <Box w="fit-content" mb={4}>
                    <Grid templateColumns="auto 1fr" gap={2}>
                      <IconContext.Provider value={{ color: '#2A8DE8', size: '20px' }}>
                        <BsCheckSquareFill />
                      </IconContext.Provider>
                      <Box>
                        <Text fontSize="xl" fontWeight="black" textAlign="left" lineHeight={1}>
                          かんたん無料会員登録
                        </Text>
                        <Text fontSize="2xs" textAlign="center" mt={2}>
                          60秒で完了！
                        </Text>
                      </Box>
                    </Grid>
                  </Box>

                  <Box w="fit-content" mb={4}>
                    <Grid templateColumns="auto 1fr" gap={2}>
                      <IconContext.Provider value={{ color: '#2A8DE8', size: '20px' }}>
                        <BsCheckSquareFill />
                      </IconContext.Provider>
                      <Box>
                        <Text fontSize="xl" fontWeight="black" textAlign="left" lineHeight={1}>
                          アプリとかんたん連携
                        </Text>
                        <Text fontSize="2xs" textAlign="center" mt={2}>
                          アプリ会員は登録不要ですぐ使える！
                        </Text>
                      </Box>
                    </Grid>
                  </Box>

                  <Box w="fit-content">
                    <Grid templateColumns="auto 1fr" gap={2}>
                      <IconContext.Provider value={{ color: '#2A8DE8', size: '20px' }}>
                        <BsCheckSquareFill />
                      </IconContext.Provider>
                      <Box>
                        <Text fontSize="xl" fontWeight="black" textAlign="left" lineHeight={1}>
                          買ったマンガは
                        </Text>
                        <Text fontSize="xl" fontWeight="black" textAlign="left">
                          WEBでもアプリでも読める
                        </Text>
                        <Flex>
                          <Text fontSize="2xs" textAlign="center" mt={1}>
                            同アカウント使用で、
                            <br />
                            WEBとアプリの本棚を共有できる！
                          </Text>
                        </Flex>
                      </Box>
                    </Grid>
                  </Box>
                </Box>
              </Center>

              <Box borderRadius="lg">
                <Stack direction={{ base: 'column', md: 'row' }} spacing={0}>
                  <VStack spacing={0} px={3} width={{ md: '50%' }} justifyContent="space-between">
                    <RoundButton variant="outline" bgColor="bg.guideAccent" color="black" as="a" href="/user/login" mt={4} fontWeight="bold">
                      ログインはこちら
                    </RoundButton>
                  </VStack>
                  <VStack spacing={0} px={3} width={{ md: '50%' }} justifyContent="space-between">
                    <RoundButton bgColor="bg.guideAccent" color="black" as="a" href="/user/login#/registrations" mt={4} fontWeight="bold">
                      会員登録はこちら
                    </RoundButton>
                  </VStack>
                </Stack>
              </Box>
            </Box>
          </Box>

          <Box bg="bg.primary" borderRadius="lg" borderWidth={1} mt={6} py={6} px={3}>
            <Box maxW="319px" m="0 auto">
              <RoundButton as="a" href="/static_page/help" fontWeight="bold">
                よくある質問はこちら
              </RoundButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
