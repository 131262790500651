import { useMemo } from 'react'
import { useAtom } from 'jotai'
import { userHashAtom } from '@!/atoms/userInfoAtom'
import { Banner } from './Banner'
import { getDevice } from '@!/Util'
import { buildAssetUrlString } from '@!/Util'
import slimNoImageBanner from '@images/slim_noimage.webp'
import membershipRegistrationSpBanner from '@images/membership_registration_sp_banner.webp'
import membershipRegistrationPcBanner from '@images/membership_registration_pc_banner.webp'

export const MembershipRegistrationBanner = (props) => {
  const device = useMemo(() => getDevice(), [])
  const banner = device === 'pc' ? membershipRegistrationPcBanner : membershipRegistrationSpBanner
  const [userHash] = useAtom(userHashAtom)

  if (userHash) {
    return null
  }

  return (
    <Banner
      src={buildAssetUrlString(banner)}
      fallbackSrc={buildAssetUrlString(slimNoImageBanner)}
      href="/user/login#/registrations"
      alt="無料会員登録はこちら"
      {...props}
    />
  )
}
