import { AppProvider } from '@!/providers'
import BookPurchaseComplete from '../containers/BookPurchaseComplete'

const BookPurchaseCompleteApp = (props) => {
  return (
    <AppProvider>
      <BookPurchaseComplete books={props.books} coupons={props.coupons} campaign={props.campaign_info} {...props} />
    </AppProvider>
  )
}

export default BookPurchaseCompleteApp
