import { useMemo } from 'react'
import { Box } from '@chakra-ui/react'
import InlineBanner_1 from '@images/inline_banner_1.webp'
import InlineBanner_2 from '@images/inline_banner_2.webp'
import InlineBanner_3 from '@images/inline_banner_3.webp'
import slimNoImageBanner from '@images/slim_noimage.webp'
import { ScrollableFeatureList } from '@!/Store/Common/components'
import { buildAssetUrlString } from '@!/Util'
import { Banner } from '@!/components/Banner'

const FeatureListWithBanners = ({ features, tabName, campaign }) => {
  const getNextBanner = useMemo(() => {
    const defaultBanners = [InlineBanner_1, InlineBanner_2, InlineBanner_3]

    const banners = campaign
      ? [
          {
            src: campaign.top_feature_1st_banner_image_url,
            href: campaign.top_feature_1st_banner_url,
          },
          {
            src: campaign.top_feature_2nd_banner_image_url,
            href: campaign.top_feature_2nd_banner_url,
          },
          {
            src: campaign.top_feature_3rd_banner_image_url,
            href: campaign.top_feature_3rd_banner_url,
          },
        ]
      : defaultBanners.map((banner) => ({
          src: buildAssetUrlString(banner),
          href: '/event',
        }))

    return (index) => (index < banners.length ? banners[index] : null)
  }, [campaign])

  // 特集の一番上と特集5つごとにインラインバナーを表示
  // ジャンルタブ選択時は、特集3つ目と、以降は特集5つごとにインラインバナーを表示
  const bannerIndexes = useMemo(() => {
    return tabName === 'home' ? [0, 5, 10] : [3, 8, 13]
  }, [tabName])

  const items = useMemo(() => {
    const featureItems = []
    let bannerIndex = 0

    features.forEach((feature, index) => {
      if (bannerIndexes.includes(index)) {
        const banner = getNextBanner(bannerIndex)
        if (banner) {
          featureItems.push(
            <Box my={6} mx={4} key={`banner-${bannerIndex}`}>
              <Banner
                src={banner.src}
                fallbackSrc={buildAssetUrlString(slimNoImageBanner)}
                href={banner.href}
                alt={campaign ? campaign.name : '全作品対象40%ポイント還元'}
                gtmClick={campaign ? `${tabName}バナー${bannerIndex + 1}_${campaign.name}` : `${tabName}バナー${bannerIndex + 1}`}
              />
            </Box>,
          )
          bannerIndex++
        }
      }

      featureItems.push(
        <ScrollableFeatureList key={feature.pathname} title={feature.title} pathname={feature.pathname} books={feature.book_titles} tabName={tabName} />,
      )
    })

    return featureItems
  }, [features, tabName, campaign, getNextBanner])

  return <Box>{items}</Box>
}

export default FeatureListWithBanners
