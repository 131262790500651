import { useEffect, useState } from 'react'
import { Modal, ModalOverlay, ModalContent, ModalBody, Button, Box, ModalFooter } from '@chakra-ui/react'

export const SafeSearchGuideModal = ({ isOpen, onClose, tagRef }) => {
  const [tagPosition, setTagPosition] = useState({ top: 0 })

  useEffect(() => {
    if (tagRef.current) {
      const rect = tagRef.current.getBoundingClientRect()
      setTagPosition({ top: rect.top - 149 })
    }
  }, [isOpen, tagRef])

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay zIndex={1000} />
      <ModalContent w="100%" maxW="372px" h="130px" borderRadius="12px" p={4} position="absolute" zIndex={1000} top={`${tagPosition.top}px`}>
        <ModalBody fontSize="sm" fontWeight="bold" p={0} mb={4}>
          TL、BLジャンルの作品を表示したい場合はセーフサーチをOFFにしてください。
        </ModalBody>
        <ModalFooter justifyContent="center" p={0}>
          <Button w="100px" variant="outline" onClick={onClose}>
            OK
          </Button>
        </ModalFooter>
        <Box position="absolute" bottom="-10px" left="68%" width="20px" height="10px" bg="white" clipPath="polygon(0% 0%, 100% 0%, 50% 100%)" />
      </ModalContent>
    </Modal>
  )
}
