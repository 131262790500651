import { useState, useEffect } from 'react'
import { useAtom } from 'jotai'
import { userHashAtom } from '@!/atoms/userInfoAtom'
import { Banner } from '@!/components/Banner'
import { buildAssetUrlString } from '@!/Util'
import slimNoImageBanner from '@images/slim_noimage.webp'

export const CampaignHeaderBanner = ({ campaign }) => {
  const [userHash] = useAtom(userHashAtom)
  const [bannerUrl, setBannerUrl] = useState(null)
  const [bannerImageUrl, setBannerImageUrl] = useState(null)

  useEffect(() => {
    if (userHash) {
      setBannerUrl(campaign.title_banner_login_url)
      setBannerImageUrl(campaign.title_banner_login_image_url)
    } else {
      setBannerUrl(campaign.title_banner_url)
      setBannerImageUrl(campaign.title_banner_image_url)
    }
  }, [userHash, campaign])

  return <Banner src={bannerImageUrl} fallbackSrc={buildAssetUrlString(slimNoImageBanner)} href={bannerUrl} alt={campaign.name} />
}
