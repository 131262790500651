import { useEffect, useState, useRef } from 'react'
import { VStack, Box, Divider, Center } from '@chakra-ui/react'
import { Config } from '@!/config/config'
import { isBlank } from '@!/Util'
import { SearchForm } from '@!/Store/Common/containers'
import { PointBackBanner, MembershipRegistrationBanner, CampaignHeaderBanner } from '@!/components/Banner'
import { BookInfo } from '@!/Store/BookDetail/components/BookInfo'
import { BookList } from '@!/Store/Common/components/BookList'
import { Paginate } from '@!/components/Paginate'
import { ScrollableFeatureList, ScrollableLatelyAccessList } from '@!/Store/Common/components'

const BookDetail = ({ currentBook }) => {
  const volumesBox = useRef(null)
  const [currentVolumes, setCurrentVolumes] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [volumeOffset, setVolumeOffset] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const volumes = currentBook.volumes
  const volumesPerPage = Config.volumesPerPage
  const campaign = currentBook.campaign_info

  useEffect(() => {
    setTotalCount(volumes.length)
    setCurrentVolumes(volumes.slice(0, volumesPerPage))
  }, [volumes])

  useEffect(() => {
    const endOffset = volumeOffset + volumesPerPage
    setCurrentVolumes(volumes.slice(volumeOffset, endOffset))
  }, [volumeOffset])

  const handlePageClick = (page) => {
    const newOffset = ((page - 1) * volumesPerPage) % volumes.length
    setVolumeOffset(newOffset)
    setCurrentPage(page)
    volumesBox.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (
    <Box>
      {campaign ? <CampaignHeaderBanner campaign={campaign} /> : <PointBackBanner />}
      <SearchForm />
      <Divider mt={2} mb={2} />
      <VStack spacing={0}>
        <BookInfo book={currentBook} />
        <Divider borderBottomWidth="12px" mt="0" />
        <Box p={4} w="100%" boxSizing="border-box" ref={volumesBox}>
          {!isBlank(currentVolumes) ? <BookList volumes={currentVolumes} rating={currentBook.rating} /> : null}
        </Box>
        <Box p={4} w="100%" boxSizing="border-box">
          <Center>
            <Paginate currentPage={currentPage} perPage={volumesPerPage} totalCount={totalCount} onPageChange={handlePageClick} />
          </Center>
        </Box>
        <Divider borderBottomWidth="2px" borderColor="bg.secondary" my={2} />
        <Box w="100%" boxSizing="border-box">
          <ScrollableLatelyAccessList />
        </Box>
        <Box w="100%" boxSizing="border-box">
          {!isBlank(currentBook.store_features)
            ? currentBook.store_features.map((feature, index) => (
                <Box key={index} mb={8}>
                  <ScrollableFeatureList key={feature.pathname} title={feature.title} pathname={feature.pathname} books={feature.book_titles} />
                </Box>
              ))
            : null}
        </Box>
        <MembershipRegistrationBanner p={4} pt={0} />
      </VStack>
    </Box>
  )
}

export default BookDetail
