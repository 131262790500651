import { useMemo, useEffect, useCallback } from 'react'
import { useAtom } from 'jotai'
import { safeSearchAtom } from '@!/atoms/safeSearchAtom'

const allTabs = [
  { name: 'home', label: 'HOME', safeSearchable: true, url: '/' },
  { name: 'man', label: '少年・青年', safeSearchable: true, url: '/store/man' },
  { name: 'woman', label: '少女・女性', safeSearchable: true, url: '/store/woman' },
  { name: 'tl', label: 'TL', safeSearchable: false, url: '/store/tl' },
  { name: 'bl', label: 'BL', safeSearchable: false, url: '/store/bl' },
]

export const useTabs = (tabName, defaultRedirectUrl = '/', withGenreNameQuery = false) => {
  const [safeSearch] = useAtom(safeSearchAtom)
  const tab = useMemo(() => allTabs.find((tab) => tab.name === tabName) || allTabs.find((tab) => tab.name === 'home'), [tabName])
  const filteredTabs = useMemo(() => (safeSearch ? allTabs.filter((tab) => tab.safeSearchable) : allTabs), [safeSearch])

  const genreName = tab.name === 'home' ? null : tab.name
  const genreLabel = genreName ? `${tab.label}ジャンル` : '全ジャンル'
  const currentTab = useMemo(() => {
    const index = filteredTabs.findIndex((t) => t.name === tab.name)

    return {
      name: tab.name,
      genreName: genreName,
      genreLabel: genreLabel,
      label: tab.label,
      url: tab.url,
      safeSearchable: tab.safeSearchable,
      index: index,
    }
  }, [tab, filteredTabs, genreName, genreLabel])

  useEffect(() => {
    if (currentTab.index === -1) {
      window.location.href = defaultRedirectUrl
    }
  }, [currentTab, defaultRedirectUrl])

  const handleTabChange = useCallback(
    (index) => {
      const { name, url } = filteredTabs[index]
      if (withGenreNameQuery) {
        if (name === 'home') {
          window.location.href = defaultRedirectUrl
        } else {
          window.location.href = `${defaultRedirectUrl}?genre_name=${name}`
        }
      } else {
        window.location.href = url
      }
    },
    [filteredTabs, withGenreNameQuery, defaultRedirectUrl],
  )

  return { currentTab, tabs: filteredTabs, handleTabChange }
}
