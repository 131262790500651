import { Box, Image, Link, VStack, HStack } from '@chakra-ui/react'
import { buildAssetUrlString } from '@!/Util'
import readNowButtonPng from '@images/read_now_button.png'
import { CoverImage } from '@!/Store/Common/components'

export const TitleList = ({ titles }) => {
  return (
    <HStack spacing={6} maxW="622px" justifyContent="center" alignItems="center" mx="auto">
      {titles.map((title, index) => (
        <VStack key={index} maxWidth="30%" mb={4}>
          <Box minW="100%">
            <Link href={`store/books?book_title_id=${title.id}`}>
              <CoverImage src={title.image_url} alt={title.name} objectFit="contain" width="100%" />
            </Link>
          </Box>
          <Link href={`store/books?book_title_id=${title.id}`}>
            <Image src={buildAssetUrlString(readNowButtonPng)} my={2} />
          </Link>
        </VStack>
      ))}
    </HStack>
  )
}
