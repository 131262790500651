import { useState, useEffect, useMemo, useCallback } from 'react'
import { useAtom } from 'jotai'
import { userHashAtom } from '@!/atoms/userInfoAtom'
import { useCheckPurchasedBooks } from '@!/Hooks'

// book: 作品の詳細情報
//  - 有料版または期間限定版の情報
//  - 通常の有料版コンテンツの場合、配信終了または無効の巻の場合がある
//  - 期間限定コンテンツの場合は配信中かつ有効巻

// book.volumes: 作品の巻数ごとの情報
//  - 有料版と期間限定版の両方が存在する場合、両方が含まれる
//  - 通常の有料版コンテンツの場合、配信終了と無効の巻も含まれる
//  - 期間限定コンテンツの場合、現在配信中で有効の巻のみが含まれる
//  - ブラックリスト登録されたコンテンツも含まれる（購入済みユーザー向け）
export function useBookDetailStatus(book, currentTime) {
  const [userHash] = useAtom(userHashAtom)
  const [currentBook, setCurrentBook] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  // 購入済み volume ids 取得
  const volumeIds = useMemo(() => book.volumes.map((volume) => volume.distribution_id), [book.volumes])
  const { data: purchasedVolumesIds, isSuccess: isVolumesSuccess } = useCheckPurchasedBooks(userHash, volumeIds)

  const currentDateTime = useMemo(() => new Date(currentTime), [currentTime])
  const permitEnd = useMemo(() => new Date(book.permit_end), [book.permit_end])

  // volumes の公開期限チェック
  const isVolumeExpired = useCallback(
    (volume) => {
      const volumePermitEnd = new Date(volume.permit_end)
      return currentDateTime > volumePermitEnd
    },
    [currentDateTime],
  )

  // book, volumes 購入済みチェック
  const isPurchased = useCallback(
    (distributionId) => {
      const isLoggedIn = !!userHash
      return isLoggedIn && isVolumesSuccess && purchasedVolumesIds?.includes(distributionId)
    },
    [userHash, isVolumesSuccess, purchasedVolumesIds],
  )

  // 購入状態 purchased、配信状態 ended（公開期限、またはブラックリスト）のステータスを付与
  useEffect(() => {
    const isBlacklisted = book.is_blacklisted
    const isPermitExpired = currentDateTime > permitEnd
    const forbiddenRating = book.rating > 2

    // book がレーティング3以上、ブラックリスト、公開期限切れ、または無効
    if (forbiddenRating || isBlacklisted || isPermitExpired || !book.active) {
      if (isPurchased(book.distribution_id)) {
        // 購入済みの場合は作品詳細を表示
        setCurrentBook({
          ...book,
          purchased: true,
          volumes: book.volumes
            .map((volume) => ({
              ...volume,
              purchased: isPurchased(volume.distribution_id),
              ended: forbiddenRating || isBlacklisted || isVolumeExpired(volume),
            }))
            .filter((volume) => volume.active || volume.purchased), // 購入されていない無効コンテンツは表示しない
        })
      } else {
        // 未購入の場合は null
        setCurrentBook(null)
      }
    } else {
      // book が配信中
      setCurrentBook({
        ...book,
        purchased: isPurchased(book.distribution_id),
        volumes: book.volumes
          .map((volume) => ({
            ...volume,
            purchased: isPurchased(volume.distribution_id),
            ended: isVolumeExpired(volume),
          }))
          .filter((volume) => (volume.active && !volume.ended) || volume.purchased), // 購入されていない無効と配信終了コンテンツは表示しない
      })
    }

    setIsLoading(false)
  }, [userHash, isVolumesSuccess, purchasedVolumesIds, book, currentDateTime, permitEnd, isPurchased, isVolumeExpired])

  return { currentBook, isLoading }
}
