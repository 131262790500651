import { useEffect, useState } from 'react'
import { useAtom } from 'jotai'
import { userHashAtom } from '@!/atoms/userInfoAtom'
import { Banner } from './Banner'
import notLoggedInHomeBannerImage from '@images/not_logged_in_home_banner.webp'
import loggedInHomeBannerImage from '@images/logged_in_home_banner.webp'
import homeBannerNoImagePng from '@images/home_banner-noimage.png'
import { buildAssetUrlString } from '@!/Util'

export const HomeBanner = ({ tabName, ...props }) => {
  const [userHash] = useAtom(userHashAtom)
  const [bannerSrc, setBannerSrc] = useState('')
  const [gtmClick, setGtmClick] = useState('')

  useEffect(() => {
    if (userHash) {
      setBannerSrc(loggedInHomeBannerImage)
      setGtmClick(`${tabName}バナー_event_after`)
    } else {
      setBannerSrc(notLoggedInHomeBannerImage)
      setGtmClick(`${tabName}バナー_event_before`)
    }
  }, [userHash, tabName])

  return (
    <Banner
      src={buildAssetUrlString(bannerSrc)}
      fallbackSrc={buildAssetUrlString(homeBannerNoImagePng)}
      href="/event"
      alt="初回限定90%OFF、全作品対象40%ポイント還元キャンペーン"
      gtmClick={gtmClick}
      {...props}
    />
  )
}
